export default {
  init() {
    // JavaScript to be fired on all pages
   
$('document').ready(function(){
  setTimeout(function(){
    $('.wa_callout').addClass('open');
  },5000);  
});
    $('.hamburger').click(function(){
      $(this).toggleClass('is-active');
      $('.nav-primary').toggleClass('open');
    });	
    $(window).scroll(function() {
      var s = $('.banner');
      var windowpos = $(window).scrollTop();
      if (windowpos <=50) {
        s.removeClass("scrolled");
      } else {
        s.addClass("scrolled");  
      }
    });
    $('.faq-accordion > h3').click(function(){
    	if($(this).hasClass('active')){ 
    		return;
    	}
    	$('.faq-accordion > h3.active').removeClass('active');
    	$(this).addClass('active');
    });
    $('.fancybox').fancybox();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
